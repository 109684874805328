import React from "react";
import logo from "../../static/logo.png";
import { Link } from "gatsby";

const Footer = (props) => {
  return (
    <footer className="footer">
      <div className="container">
        <nav className="footer-nav">
          <div className="grid">
            <div className="grid__item large--one-quarter">
              <p className="footer__title">Contact</p>
              <ul className="footer-nav__items">
                <li className="footer-nav__item footer-nav__item--no-m">
                  <a
                    href="https://goo.gl/maps/t93mYPBLcTbcVH3r8"
                    className="footer-nav__text"
                  >
                    Lissewegestraat 7
                    <br />
                    8370 Blankenberge
                  </a>
                </li>
                <li className="footer-nav__item footer-nav__item--no-m">
                  <p className="footer-nav__text">
                    <span>
                      <strong>Kinesitherapie</strong>
                      <br />
                      <a href="tel:0032418284">050 / 41 82 84</a>
                    </span>
                  </p>
                  <p className="footer-nav__text">
                    <span>
                      <strong>Osteopathie</strong>
                      <br />
                      <a href="tel:0032484160666">0484 / 16 06 66</a>
                    </span>
                  </p>
                  <p className="footer-nav__text footer-nav__text--small">
                    Enkel op afspraak
                    <br />
                    Wanneer u een afspraak niet kunt naleven, gelieve 24 uur op
                    voorhand te verwittigen.
                  </p>
                </li>
              </ul>
            </div>
            <div className="grid__item large--one-quarter">
              <p className="footer__title">Menu</p>
              <ul className="footer-nav__items footer-nav__items--column">
                <li className="footer-nav__item footer-nav__item--no-m">
                  <Link to="/specialisaties" className="nav-link">
                    Specialisaties
                  </Link>
                </li>
                <li className="footer-nav__item footer-nav__item--no-m">
                  <Link to="/therapeuten" className="nav-link">
                    Therapeuten
                  </Link>
                </li>
                <li className="footer-nav__item footer-nav__item--no-m">
                  <Link to="/" className="nav-link">
                    Osteopathie
                  </Link>
                </li>
                <li className="footer-nav__item footer-nav__item--no-m">
                  <Link to="/contact" className="nav-link">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            <div className="grid__item large--one-quarter">
              <p className="footer__title">Tarieven</p>
              <ul className="footer-nav__items footer-nav__items--column">
                <li className="footer-nav__item footer-nav__item--no-m">
                  <a href="https://www.riziv.fgov.be/nl/themas/kost-terugbetaling/door-ziekenfonds/individuele-verzorging/honoraires/Paginas/kine-nl.aspx" className="nav-link" target="_blank" rel="noopener">
                    Onze tarieven
                  </a>
                </li>
              </ul>
            </div>
            <div className="grid__item large--one-quarter">
              <p className="footer__title">Volg ons</p>
              <ul className="footer-nav__items footer-nav__items--column">
                <li className="footer-nav__item footer-nav__item--no-m">
                  <a
                    className="nav-link"
                    href="https://www.facebook.com/kine.osteo.hagers.be/"
                  >
                    Facebook
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="footer-nav__logo">
            <div className="visual visual--contain">
              <img src={logo} alt="" />
            </div>
          </div> */}
        </nav>
      </div>
      <div className="footer__bottom">
        {new Date().getFullYear()} – Kinesitherapie- en osteopathiepraktijk
        Hagers
      </div>
    </footer>
  );
};

export default Footer;
