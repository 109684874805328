import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import logo from "../../static/logo.png";

const Layout = (props) => {
  let layoutClass = "layout";

  return (
    <div className={`${layoutClass} layout--fixed`}>
      <div className="announcement-bar">
        <p>
          AFSPRAAK of INFO, BEL <a href="tel:003250418284"> 050 / 41 82 84</a>
        </p>
      </div>
      <Header headerLogo={logo} />
      <div className="page">{props.children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
