import React from "react";
import { Link } from "gatsby";

const Header = (props) => {
  // const [headerClassName, setHeaderClassName] = useState("");
  // const handleScroll = () => {
  //   if (headerClassName !== " header--compact" && window.pageYOffset >= 30) {
  //     setHeaderClassName(" header--compact");
  //   } else if (
  //     headerClassName === " header--compact" &&
  //     window.pageYOffset <= 20
  //   ) {
  //     setHeaderClassName("");
  //   }
  // };

  // useEffect(() => {
  //   window.onscroll = handleScroll;
  // }, [headerClassName]);

  const openNav = () => {
    document.getElementById("mobileNav").style.transform = "translateX(0)";
    document
      .querySelector(".header-mobile")
      .classList.add("header-mobile--open");
  };

  const closeNav = () => {
    document.getElementById("mobileNav").style.transform = "translateX(100%)";
    document
      .querySelector(".header-mobile")
      .classList.remove("header-mobile--open");
  };

  return (
    <header className={`header`}>
      <div className={`header-mobile`}>
        <a href="javascript:void(0)" className="button-open" onClick={openNav}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 18">
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                <path d="M24,10H6A1,1,0,0,1,6,8H24a1,1,0,0,1,0,2Zm-5,8H1a1,1,0,0,1,0-2H19a1,1,0,0,1,0,2ZM19,2H1A1,1,0,0,1,1,0H19a1,1,0,0,1,0,2Z" />
              </g>
            </g>
          </svg>
        </a>
        <a
          href="javascript:void(0)"
          className="button-close"
          onClick={closeNav}
        ></a>
        <nav id="mobileNav" className={`nav`}>
          <ul className="nav-items--mobile">
            <li className="nav-item">
              <Link
                className="nav-link nav-link--mobile"
                to="/"
                activeClassName="active"
              >
                home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link nav-link--mobile"
                to="/therapeuten"
                activeClassName="active"
              >
                therapeuten
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link nav-link--mobile"
                to="/specialisaties"
                activeClassName="active"
              >
                specialisaties
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/osteopathie"
                activeClassName="active"
              >
                osteopathie
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link nav-link--mobile"
                to="/contact"
                activeClassName="active"
              >
                contact
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      <nav className="nav">
        <div className="container">
          <ul className="nav-items">
            <li className="nav-item">
              <Link className="nav-link" to="/" activeClassName="active">
                home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/therapeuten"
                activeClassName="active"
              >
                therapeuten
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/specialisaties"
                activeClassName="active"
              >
                specialisaties
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/osteopathie"
                activeClassName="active"
              >
                osteopathie
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact" activeClassName="active">
                contact
              </Link>
            </li>
          </ul>
        </div>
      </nav>
      <Link to="/" className="header__logo">
        <img src={props.headerLogo} alt="" />
      </Link>
    </header>
  );
};

export default Header;
